<template>
    <v-row id="invoiceContent" v-if="isShow">
        <div style="width: 100%; overflow: hidden;margin: 0; padding: 0; page-break-after: always;" v-for="printObj in printData" v-bind:key="printObj.printId">
            <div v-if="printObj.type != 'return'">
                <div v-if="isKlassy">
                    <div v-if="receiptTemplate == 'A5'" style="padding-top: 10px;overflow: hidden;">
                        <div v-if="printObj.invoiceType != 'normal'" style="width: 100%;border-bottom: 1px solid #000;overflow: hidden;position: relative;">
                            <div style="width: 25%; padding: 0; text-align: left;position: absolute; left: 0; top: 0;">
                                <img
                                    class="mt-0 logo"
                                    style="height:90px;width: auto;margin: 0;"
                                    :src="logoUrl"
                                    alt=""
                                />
                            </div>
                            <div style="width: 100%; float: left;text-align: center;padding: 0;">
                                <div style="width: 100%; padding: 0; text-align: center;font-size: 10px;font-weight: bold;padding: 0px;padding-bottom: 0px;font-family: 'Khmer OS Muol';">
                                    ឌីខេអេចអិន ក្លាសស៊ី ស៊ីកនេតឈ័រ ឯ.ក
                                </div>
                                <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: bold;padding: 0px;padding-bottom: 0;">
                                    DKHN KLASSY SIGNATURE CO., LTD.
                                </div>
                                <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                    លេខអតត្តសញ្ញាណកម្មសារពើពន្ធ (VAT-TIN): {{ institute.vatTin }}
                                </div>
                                <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                    អាស័យដ្ឋាន៖ <span style="font-size: 9px;" v-html="institute.companyAddress"></span>
                                </div>
                                <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                    លេខទូរស័ព្ទ/ Tel: {{ institute.companyPhone }}
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;text-align: center;overflow: hidden;">
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 12px;font-weight: bold;line-height: 14px;padding-top: 10px; font-family: 'Khmer OS Muol';">
                                <span style="font-size: 12px;font-weight: bold;line-height: 14px;padding-top: 10px; font-family: 'Khmer OS Muol';" v-if="printObj.invoiceType != 'vat'">វិក្កយបត្រ</span>
                                <span style="font-size: 12px;font-weight: bold;line-height: 14px;padding-top: 10px; font-family: 'Khmer OS Muol';" v-else>វិក្កយបត្រអាករ</span>
                                <br>
                                <span style="font-size: 8px;" v-if="printObj.invoiceType == 'commercial'"> Commercial Invoice</span>
                                <span style="font-size: 8px;" v-else-if="printObj.invoiceType == 'vat'"> VAT Invoice</span>
                                <span style="font-size: 8px;" v-else>Invoice</span>
                            </div>
                        </div>
                        <div style="width: 100%;text-align: center;overflow: hidden;">
                            <div style="width: 60%;overflow: hidden;text-align: left;float: left;font-size: 9px;font-weight: bold;">
                                <div style="width: 100%;overflow: hidden;">អតិថិជន/ CUSTOMER:</div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                        ឈ្មោះអតិថិជន៖
                                    </div>
                                    <div style="width: 75%;float: left;overflow: hidden;font-size: 9px;">
                                        {{printObj.customer.name}}
                                    </div>
                                </div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                        អាស័យដ្ឋាន៖
                                    </div>
                                    <div style="width: 75%;float: left;overflow: hidden;font-weight: normal;font-size: 9px;">
                                        {{printObj.customer.address}}
                                    </div>
                                </div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                        លេខទូរស័ព្ទ៖
                                    </div>
                                    <div style="width: 75%;float: left;overflow: hidden;font-size: 9px;">
                                        {{printObj.customer.phone}}
                                    </div>
                                </div>
                                <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                        លេខអតត្តសញ្ញាណកម្មសារពើពន្ធ (VAT-TIN)៖
                                    </div>
                                    <div style="width: 75%;float: left;overflow: hidden;font-size: 9px;">
                                        {{printObj.customer.vatnum}}
                                    </div>
                                </div>
                            </div>
                            <div style="width: 40%;overflow: hidden;text-align: right;float: left;font-weight: bold;font-size: 9px;">
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                        លេខវិក័យប័ត្រ៖
                                    </div>
                                    <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                        {{printObj.txnNumber}}
                                    </div>
                                </div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                        កាលបរិច្ឆេទ៖
                                    </div>
                                    <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                        {{dateTimeFormat(printObj.issuedDate)}}
                                    </div>
                                </div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                        អ្នកគិតលុយ៖
                                    </div>
                                    <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                        {{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}
                                    </div>
                                </div>
                                <div style="width: 100%;overflow: hidden;clear:both">
                                    <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                        អាត្រាប្តូរប្រាក់៖
                                    </div>
                                    <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                        1 USD = {{printObj.exchangeRate.replace('KHR', '')}} រៀល
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;padding: 0;overflow: hidden;padding-top: 10px;">
                            <v-simple-table fixed-header  class="flex-1" style="border: 1px solid #000!important;" >
                                <template v-slot:default >
                                    <thead class="th_invoice" >
                                        <tr>
                                            <th style="border: 0px;background: none;padding-left: 10px; text-align: left!important;border:none!important;font-size: 9px !important;" width="500" class=" th_invoice text-left">
                                                បរិយាយមុខទំនិញ <br>Description
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;"  width="100" class=" th_invoice text-left">
                                                បរិមាណ<br>Quantity
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;" width="150" class=" th_invoice text-left">
                                                តម្លៃឯកតា<br>Price
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;" width="150" class=" th_invoice_r text-left">
                                                សរុប<br>Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr 
                                            v-for="d in printObj.itemLines" 
                                            v-bind:key="d.id" >
                                            <td style="border: none!important;text-align: left;font-size: 9px !important;padding-left: 10px!important;" class="tb_receipt" v-html="d.description"></td>
                                            <td style="border: none!important;text-align: right;padding: 0;font-size: 9px !important;" class="tb_receipt">{{ d.qty }}</td>
                                            <td style="border: none!important;text-align: right;padding: 0;font-size: 9px !important;" class="tb_receipt">{{ numberFormatS(d.price, printObj.baseRate.code) }}</td>
                                            <td style="border: none!important;text-align: right;font-size: 9px !important;padding-right: 10px;" class="tb_receipt">{{ numberFormatS(d.amount, printObj.baseRate.code) }}</td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 1">
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 1">
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 1">
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 2" >
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 2">
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                        <tr v-if="printObj.itemLines.length == 3">
                                            <td colspan="4" style="height: 26.5px;"></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr style="padding: 2px; border-top: 1px solid #000;">
                                            <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                                <span style="padding: 2px;font-size: 9px;" >សរុប(ដុល្លារ) / Subtotal (USD)</span>
                                            </td>
                                            <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                                {{ numberFormatS(printObj.subTotal,printObj.baseRate.code) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                                <span style="padding: 2px;font-size: 9px;" >បញ្ចុះតំលៃ(ដុល្លារ) / Discount (USD)</span>
                                            </td>
                                            <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                                {{ numberFormatS(printObj.discount, printObj.baseRate.code) }}
                                            </td>
                                        </tr>
                                        <tr v-if="printObj.invoiceType == 'vat'">
                                            <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                                <span style="padding: 2px;font-size: 9px;" >អាករលើតម្លៃបន្ថែម (VAT 10%)</span>
                                            </td>
                                            <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                                {{ numberFormatS(printObj.tax, printObj.baseRate.code) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                                <span style="padding: 2px;font-size: 9px;" >សរុបរួម(ដុល្លារ) / Grand Total (USD)</span><br>
                                                <span style="padding: 2px;font-size: 9px;" v-if="printObj.invoiceType == 'commercial'">(រួមបញ្ចូលទាំងពន្ធ / All VAT Included)</span>
                                            </td>
                                            <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                                {{numberFormatS(printObj.total, printObj.baseRate.symbol)}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;font-weight: normal;padding-top: 5px;">
                                                <span style="padding: 2px;font-size: 9px;" >ទឹកប្រាក់សរុបជារៀលសម្រាប់កាតព្វកិច្ចប្រកាសពន្ធតែប៉ុណ្ណោះ <br>(Total Amount in KHR for Tax Filling Obligation Only):</span>
                                            </td>
                                            <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important; padding-right: 10px;">
                                                {{ numberFormatS(parseFloat(printObj.secondRate.rate) != 1 ? (printObj.total * parseFloat(printObj.secondRate.rate)) : (printObj.total * parseFloat(printObj.baseRate.rate)), printObj.secondRate.symbol)}} រៀល
                                            </td>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </div>
                        <div style="width: 100%;overflow: hidden;text-align: left;float: left;font-weight: bold;font-size: 9px;margin-top: 10px;">
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: bold;">
                                លក្ខខណ្ឌផ្សេងៗ៖
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                1. សូមពិនិត្យទំនិញឱ្យបានត្រឹមត្រូវមុននឹងចាកចេញ ឬក៏ទទួលយក។
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                2. អតិថិជន ឯកភាពថានាឡិកាដែលបានយកចេញទៅគឺ មានសភាពថ្មី ១០០% ។
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                3. យើងខ្ញុំពុំទទួលខុសត្រូវរាល់ការ បែកបាក់ ខូតខាត ឫឆ្កូត បន្ទាប់ពីអតិថិជនបានចុះហត្ថលេខាទទួលឡើយ។
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                4. ទំនិញឫនាឡិកាដែលជាកាដូពីហាង ឬជាការបញ្ចុះតម្លៃពុំអាចធ្វើការផ្លាស់ប្តូរបានទេ។
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                5. អតិថិជនអាចធ្វើការផ្លាស់ប្តូរទំនិញឫនាឡិកាបានក្នុងរយៈពេល ៧២ម៉ោង រាប់ពីពេលដែលអតិថិជនធ្វើការជាវ ក្នុងលក្ខខណ្ឌដូចខាងក្រោម៖
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.1 ទំនិញឬនាឡិកាដែលទិញនៅថ្មីមិនទាន់បានប្រើប្រាស់ ទក់ស្បែក កូដ កាត់ ចោះខ្សែ ឬដាច់ប្លាស្ទិកគ្របតួរបស់នាឡិកាដែលខុសទ្រង់ទ្រាយថ្មី។</p>
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.2 ក្នុងករណីអតិថិជនប្តូរជាមួយទំនិញដែលមានតម្លៃទាបជាងតម្លៃទំនិញដែលត្រូវប្តូរ ខាងយើងខ្ញុំនឹងមិនប្រគល់ទឹកប្រាក់ដែលនៅសល់នោះទេ។</p>
                            </div>
                            <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                                <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.3 មិនអនុញ្ញាតិអោយមានការផ្លាស់ប្តូរទំនិញឫនាឡិការលើសពី១លើកទេ។</p>
                            </div>
                        </div>
                        <div style="overflow: hidden;width: 100%;padding-top: 15px;">
                            <div style="width: 100%;overflow: hidden;position: relative;text-align: left;clear: both">
                                <div style="width: 30%;text-align: center; float: left;font-size: 9px;">
                                    បានអាន និងយល់ស្របតាមលក្ខខណ្ឌខាងលើ
                                </div>
                            </div>
                            <div style="width: 30%; float: left;height: 90px;border-bottom: 1px solid #000;text-align: center;font-size: 9px;font-weight: normal;">
                            
                                <span style="font-size: 9px;font-weight: bold;">
                                    ហត្ថលេខាននិងឈ្មោះអតិថិជន
                                </span>
                            </div>
                            <div style="width: 30%; float: right;height: 90px;border-bottom: 1px solid #000;text-align: center;font-size: 9px;font-weight: normal;">
                                
                                <span style="font-size: 9px;font-weight: bold;">
                                    ហត្ថលេខានិងឈ្មោះអ្នកលក់
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="width: 100%; padding: 0; text-align: center;">
                            <img
                                class="mt-0 logo"
                                style="height:100px;width: auto;margin: 0;"
                                :src="logoUrl"
                                alt=""
                            />
                        </div>
                        <div style="width: 100%; padding: 0; text-align: center;font-size: 14px;font-weight: bold;padding: 2px;">
                            ឌីខេអេចអិន ក្លាសស៊ី ស៊ីកនេតឈ័រ ឯ.ក
                        </div>
                        <div style="width: 100%; padding: 0; text-align: center;font-size: 12px;font-weight: bold;padding: 2px;">
                            DKHN KLASSY SIGNATURE CO., LTD.
                        </div>
                        <div style="width: 100%; padding: 0; text-align: center;font-size: 12px;font-weight: normal;padding: 2px;">
                            លេខអតត្តសញ្ញាណកម្មសារពើពន្ធ (VAT-TIN): {{ institute.vatTin }}
                        </div>
                        <div style="width: 100%; padding: 0; text-align: center;font-size: 12px;font-weight: normal;padding: 2px;">
                            អាស័យដ្ឋាន៖ {{ institute.companyAddress }}
                        </div>
                        <div v-if="f.allowTitle" style="width: 100%; padding: 0; text-align: center;border-bottom: 1px solid #000;background-color: #fff;overflow: hidden;margin-bottom: 5px;">
                            <h2 style="margin-top: 0!important; text-align: center!important;margin-bottom: 0;font-size: 16px;background: none!important;" class="text-left mt-4">{{f.title != '' ? f.title : dt.title}}</h2>
                        </div>
                        <div style="width: 100%;overflow: hidden;">
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;">
                                    Branch: {{ activeStore.name }}
                                </div>
                            </div>
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;text-align: right;">
                                    លេខទូរស័ព្ទ/ Tel: {{ institute.companyPhone }}
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;overflow: hidden;">
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;">
                                    អ្នកគិតប្រាក់/ Cashier: 
                                </div>
                            </div>
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;text-align: right;">
                                    {{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;overflow: hidden;">
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;">
                                    លេខវិក័យប័ត្រ/ Invoice No: 
                                </div>
                            </div>
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;text-align: right;">
                                    {{printObj.txnNumber}}
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;overflow: hidden;">
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;">
                                    កាលបរិច្ឆេទ/ Date: 
                                </div>
                            </div>
                            <div style="width: 50%;float: left;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;text-align: right;">
                                    {{dateTimeFormat(printObj.issuedDate)}}
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;overflow: hidden;">
                            <div style="width: 50%;float: left;border-bottom: 1px solid #000;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;">
                                    អត្រាប្តូរប្រាក់/ Exchange Rate: 
                                </div>
                            </div>
                            <div style="width: 50%;float: left;border-bottom: 1px solid #000;">
                                <div style="width: 100%;padding: 0;overflow: hidden;font-size: 12px;text-align: right;">
                                    1 USD = {{printObj.exchangeRate.replace('KHR', '')}} រៀល
                                </div>
                            </div>
                        </div>
                        <div  style="width: 100%;padding: 0;overflow: hidden;padding-top: 20px;">
                            <v-simple-table fixed-header  class="flex-1" >
                                <template v-slot:default >
                                    <thead class="th_invoice" >
                                        <tr>
                                            <th style="border: 0px;background: none;padding-left: 0; text-align: left!important;border:none!important;" width="500" class=" th_invoice text-left">
                                                បរិយាយមុខទំនិញ <br>Description
                                            </th>
                                            <th style="border: 0px;text-align: center!important;border:none!important;" v-if="f.allowUom" class=" th_invoice text-left">
                                                {{dt.unit}}
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;" v-if="f.allowQty"  width="100" class=" th_invoice text-left">
                                                បរិមាណ<br>QTY
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;" v-if="f.allowPrice" width="150" class=" th_invoice text-left">
                                                តម្លៃឯកតា<br>Price
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;" v-if="f.allowDis" width="100" class=" th_invoice text-left">
                                                {{dt.discount}}
                                            </th>
                                            <th style="border: 0px;text-align: right!important;border:none!important;" width="150" class=" th_invoice_r text-left">
                                                សរុប<br>Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr 
                                            v-for="d in printObj.itemLines" 
                                            v-bind:key="d.id" >
                                            <td style="border: none!important;padding-left: 0;text-align: left;padding: 0;border-bottom: 1px dotted #000;" class="tb_receipt" v-html="d.description"></td>
                                            <td style="border: none!important;text-align: center;padding: 0;border-bottom: 1px dotted #000;" v-if="f.allowUom" class="tb_receipt">{{ d.uom.code }}</td>
                                            <td style="border: none!important;text-align: right;padding: 0;border-bottom: 1px dotted #000;" v-if="f.allowQty" class="tb_receipt">{{ d.qty }}</td>
                                            <td style="border: none!important;text-align: right;padding: 0;border-bottom: 1px dotted #000;" v-if="f.allowPrice" class="tb_receipt">{{ numberFormatS(d.price, printObj.baseRate.code) }}</td>
                                            <td style="border: none!important;text-align: right;padding: 0;border-bottom: 1px dotted #000;" v-if="f.allowDis" class="tb_receipt">{{ numberFormatS(d.discount, printObj.baseRate.code) }}</td>
                                            <td style="border: none!important;text-align: right;padding: 0;border-bottom: 1px dotted #000;" class="tb_receipt">{{ numberFormatS(d.amount, printObj.baseRate.code) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                        <div style="width: 100%;padding: 0;border-top: 1px solid #000;overflow: hidden;padding-top: 20px">
                            <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 14px;">
                                សរុប (ដុល្លា) / Subtotal (USD)
                            </div>
                            <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                {{ numberFormatS(printObj.subTotal,printObj.baseRate.code) }}
                            </div>
                        </div>
                        <div style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 14px;">
                                បញ្ចុះតំលៃ (ដុល្លា) / Discount (USD)
                            </div>
                            <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                {{ numberFormatS(printObj.discount, printObj.baseRate.code) }}
                            </div>
                        </div>
                        <div style="width: 100%;background-color: #ccc!important;overflow: hidden; padding: 10px 0;">
                            <div style="width: 100%;padding: 0;overflow: hidden;">
                                <div style="width: 50%; float: left; padding: 0;margin: 0;font-size: 14px;">
                                    សរុប (ដុល្លា) / Total (USD)
                                </div>
                                <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 16px;">
                                    {{numberFormatS(printObj.total, printObj.baseRate.symbol)}}
                                </div>
                            </div>
                            <div style="width: 100%;padding: 0;overflow: hidden;">
                                <div style="width: 50%; float: left; padding: 0;margin: 0;font-size: 14px;">
                                    សរុប (រៀល) / Total (Riel)
                                </div>
                                <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 16px;">
                                    {{ numberFormatS(parseFloat(printObj.secondRate.rate) != 1 ? (printObj.total * parseFloat(printObj.secondRate.rate)) : (printObj.total * parseFloat(printObj.baseRate.rate)), printObj.secondRate.symbol)}}
                                </div>
                            </div>
                        </div>
                        <div v-if="printObj.paidBy == 'Cash'" style="padding-top: 20px; border-bottom: 1px solid #000;">
                            <div v-if="f.allowReciept" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                    ទទួលប្រាក់ (ដុល្លា) / Received Cash (USD)
                                </div>
                                <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                    {{numberFormat(printObj.usdPaidAmt)}}
                                </div>
                            </div>
                            <div v-if="f.allowReciept" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                    ទទួលប្រាក់ (រៀល) / Received Cash (Riel)
                                </div>
                                <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                    {{numberFormatS(printObj.khrPaidAmt, printObj.secondRate.symbol)}} 
                                </div>
                            </div>
                            <div v-if="printObj.bankPaidAmt > 0" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                    ទទួលប្រាក់តាមធនាគារ / Received From Bank
                                </div>
                                <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                    {{numberFormat(printObj.bankPaidAmt)}} 
                                </div>
                            </div>
                            <div v-if="printObj.otherPaidAmt > 0" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 100%; overflow: hidden;" v-for="d in printObj.otherPays" v-bind:key="d.id">
                                    <div v-if="d.amount > 0" style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                        ទទួលប្រាក់តាម / Received From ({{d.paymentMethod}})
                                    </div>
                                    <div v-if="d.amount > 0" style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                        {{numberFormat(d.amount)}} 
                                    </div>
                                </div>
                            </div>
                            <div v-if="f.allowChange" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                    ប្រាក់អាប់ (ដុល្លា) / Change (USD)
                                </div>
                                <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                    {{numberFormat(printObj.exchange.base)}}
                                </div>
                            </div>
                            <div v-if="f.allowChange" style="width: 100%;padding: 0px;overflow: hidden;">
                                <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 13px;">
                                    ប្រាក់អាប់ (រៀល) / Change (Riel)
                                </div>
                                <div style="width: 30%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                    {{numberFormatS(printObj.exchange.secondary, printObj.secondRate.symbol)}} 
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 70%; float: left; padding: 0;margin: 0;font-size: 14px;text-align: right;">
                                Total number of items sold:
                            </div>
                            <div style="width: 30%; float: right; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 14px;">
                                {{  totalItems }}
                            </div>
                        </div>
                        <div style="width: 100%;padding: 0;overflow: hidden; font-size: 14px; text-align: center; padding: 20px 0;" v-if="printObj.invoiceType == 'commercial'">
                            All Tax Included
                        </div>
                        <div v-if="f.thankWord!=''" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 100%; text-align: center; padding: 0;margin: 0;font-size: 14px;text-align: center;" v-html="f.thankWord">
                            </div>
                        </div>
                        <v-col class="text-right my-4" sm="12" cols="12" style="text-align: center!important;margin: 0!important;padding: 6px;background: none;border-top: 1px solid #eee;overflow: hidden;">
                            <img src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/benchlogo.png" style="height: 40px; width: auto;" />
                        </v-col>
                    </div>
                </div>
                <div v-else>
                    <div v-if="f.allowLogo" style="width: 100%; overflow: hidden;text-align: left;border-bottom: 1px solid #000;padding-bottom: 5px;overflow: hidden;">
                        <div style="width: 27%; float: left;overflow: hidden;">
                            <img
                                class="mt-0 logo"
                                style="width:100%;height: auto;margin: 0;"
                                :src="logoUrl"
                                alt=""
                            />
                        </div>
                        <div style="width: 70%;float: right;overflow: hidden;">
                            <div v-if="f.allowVat" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.vat}} : <strong>{{ institute.vatTin }}</strong>
                            </div>
                            <div v-if="f.allowAddress" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.address}} : <strong>{{ institute.companyAddress }}</strong>
                            </div>
                            <div v-if="f.allowPhone" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.phone}} : <strong>{{ institute.companyPhone }}</strong>
                            </div>
                        </div>
                    </div>
                    <div v-else style="width: 100%;border-bottom: 1px solid #000;overflow: hidden;padding-bottom: 5px;">
                        <div v-if="f.allowVat" style="width: 100%;padding: 0;overflow: hidden;">
                            {{dt.vat}} : <strong>{{ institute.vatTin }}</strong>
                        </div>
                        <div v-if="f.allowAddress" style="width: 100%;padding: 0;overflow: hidden;">
                            {{dt.address}} : <strong>{{ institute.companyAddress }}</strong>
                        </div>
                        <div v-if="f.allowPhone" style="width: 100%;padding: 0;overflow: hidden;">
                            {{dt.phone}} : <strong>{{ institute.companyPhone }}</strong>
                        </div>
                    </div>
                    <div v-if="f.allowTitle" style="width: 100%; padding: 0; text-align: center;border-bottom: 1px solid #000;background-color: #fff;overflow: hidden;margin-bottom: 5px;">
                        <h2 style="margin-top: 0!important; text-align: center!important;margin-bottom: 0;font-size: 16px;background: none!important;" class="text-left mt-4">{{f.title != '' ? f.title : dt.title}}</h2>
                    </div>
                    <div v-if="g.receiptTemplate == '80mm' || g.receiptTemplate == '58mm'" style="width: 100%; overflow: hidden;">
                        <div v-if="f.allowTNum" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.number}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.txnNumber}}</strong>
                            </div>
                        </div>
                        <div v-if="f.allowStore" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.storeName}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{ activeStore.name }}</strong>
                            </div>
                        </div>
                        <div v-if="f.allowCashier" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.cashier}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}</strong>
                            </div>
                        </div>
                        <div v-if="f.customerType" style="width: 100%;padding: 2px;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.customerType}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.customer.type}}</strong>
                            </div>
                        </div>
                        <div style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.customer}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.customer.name}}</strong>
                            </div>
                        </div>
                        <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{$t('phone')}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.customer.phone}}</strong>
                            </div>
                        </div>
                        <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{$t('vattin_number')}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.customer.vatnum}}</strong>
                            </div>
                        </div>
                        <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{$t('address')}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.customer.address}}</strong>
                            </div>
                        </div>
                        <div v-if="f.timeIn" style="width: 100%;padding: 2px;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.timeIn}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.timeIn}}</strong>
                            </div>
                        </div>
                        <div v-if="f.timeOut" style="width: 100%;padding: 2px;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.timeOut}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.timeOut}}</strong>
                            </div>
                        </div>
                        <div v-if="f.orderNum" style="width: 100%;padding: 2px;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.orderNum}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.orderNumber}}</strong>
                            </div>
                        </div>
                        <div v-if="f.allowDate" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.date}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{dateTimeFormat(printObj.issuedDate)}}</strong>
                            </div>
                        </div>
                        <div v-if="f.allowCounter" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.counter}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{ counterName }}</strong>
                            </div>
                        </div>
                        <div v-if="f.allowExRate" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.exchange}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <div v-if="printObj.secondRate.code == 'KHR'">
                                    <strong>{{printObj.secondRate.rate}} {{printObj.secondRate.code}}</strong>
                                </div>
                                <div v-else>
                                <strong>1 {{printObj.secondRate.code}} = {{1 / parseFloat(printObj.baseRate.rate)}} {{printObj.baseRate.code}}</strong>
                                </div>
                            </div>
                        </div>
                        <div v-if="g.saleUnitItem" style="width: 100%;padding: 0;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{dt.saleunit}}
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                <strong>{{printObj.saleUnit.name || ''}} </strong>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="width: 50%;float: left;">
                            <div v-if="f.allowTNum" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.number}} : <strong>{{printObj.txnNumber}}</strong>
                            </div>
                            <div v-if="f.allowStore" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.storeName}} : <strong>{{ activeStore.name }}</strong>
                            </div>
                            <div v-if="f.allowCashier" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.cashier}} : <strong>{{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}</strong>
                            </div>
                            <div v-if="f.customerType" style="width: 100%;padding: 2px;">
                                {{dt.customerType}} : <strong>{{printObj.customer.type}}</strong>
                            </div>
                            <div style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.customer}} : <strong>{{printObj.customer.name}}</strong>
                            </div>
                            <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                                {{$t('phone')}} : <strong>{{printObj.customer.phone}}</strong>
                            </div>
                            <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                                {{$t('vattin_number')}} : <strong>{{printObj.customer.vatnum}}</strong>
                            </div>
                            <div v-if="printObj.invoiceType == 'vat'" style="width: 100%;padding: 0;overflow: hidden;">
                                {{$t('address')}} : <strong>{{printObj.customer.address}}</strong>
                            </div>
                            <div v-if="f.timeIn" style="width: 100%;padding: 2px;">
                                {{dt.timeIn}} : <strong>{{printObj.timeIn}}</strong>
                            </div>
                        </div>
                        <div style="width: 50%;float: left;">
                            <div v-if="f.allowDate" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.date}} : <strong>{{dateTimeFormat(printObj.issuedDate)}}</strong>
                            </div>
                            <div v-if="f.allowCounter" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.counter}} : <strong>{{ counterName }}</strong>
                            </div>
                            <div v-if="f.allowExRate" style="width: 100%;padding: 0;overflow: hidden;">
                                <div v-if="printObj.secondRate.code == 'KHR'">
                                    {{dt.exchange}} : <strong>{{printObj.secondRate.rate}} {{printObj.secondRate.code}}</strong>
                                </div>
                                <div v-else>
                                    {{dt.exchange}} : <strong>1 {{printObj.baseRate.code}} = {{1 / parseFloat(printObj.secondRate.rate)}} {{printObj.secondRate.code}}</strong>
                                </div>
                            </div>
                            <div v-if="g.saleUnitItem" style="width: 100%;padding: 0;overflow: hidden;">
                                {{dt.saleunit}} : <strong>{{printObj.saleUnit.name || ''}}</strong>
                            </div>
                            <div v-if="f.orderNum" style="width: 100%;padding: 2px;">
                                {{dt.orderNum}} : <strong>{{printObj.orderNumber}}</strong>
                            </div>
                            <div v-if="f.timeOut" style="width: 100%;padding: 2px;">
                                {{dt.timeOut}} : <strong>{{printObj.timeOut}}</strong>
                            </div>
                        </div>
                    </div>
                    <div  style="width: 100%;padding: 0;overflow: hidden;">
                        <v-simple-table fixed-header  class="flex-1" >
                            <template v-slot:default >
                                <thead class="th_invoice" v-if="f.lang != 1">
                                    <tr>
                                        <th style="border: 0px;border-top: 1px solid #000;background: none;padding-left: 0; border-bottom: 1px solid #000!important;text-align: left!important;" width="500" class=" th_invoice text-left">
                                            {{dt.description}}
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: center!important;" v-if="f.allowUom" class=" th_invoice text-left">
                                            {{dt.unit}}
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: center!important;" v-if="f.allowQty"  width="100" class=" th_invoice text-left">
                                            {{dt.qty}}
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" v-if="f.allowPrice" width="200" class=" th_invoice text-left">
                                            {{dt.price}}
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" v-if="f.allowDis" width="200" class=" th_invoice text-left">
                                            {{dt.discount}}
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" width="200" class=" th_invoice_r text-left">
                                            {{dt.amount}}
                                        </th>
                                    </tr>
                                </thead>
                                <thead class="th_invoice" v-else>
                                    <tr>
                                        <th style="border: 0px;border-top: 1px solid #000;background: none;padding-left: 0; border-bottom: 1px solid #000!important;text-align: left!important;" width="500" class=" th_invoice text-left">
                                            ពិពណ៌នា<br>(Des.)
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: center!important;" v-if="f.allowUom" class=" th_invoice text-left">
                                            ខ្នាត<br>(UOM)
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: center!important;" v-if="f.allowQty"  width="100" class=" th_invoice text-left">
                                            ចំនួន<br>(Qty)
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" v-if="f.allowPrice" width="200" class=" th_invoice text-left">
                                            ថ្លៃ<br>(Price)
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" v-if="f.allowDis" width="200" class=" th_invoice text-left">
                                            ចុះថ្លៃ<br>(Dis.)
                                        </th>
                                        <th style="border: 0px;border-top: 1px solid #000;background: #eee; border-bottom: 1px solid #000!important;text-align: right!important;" width="200" class=" th_invoice_r text-left">
                                            សរុប<br>(Amount)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr 
                                        v-for="d in printObj.itemLines" 
                                        v-bind:key="d.id" >
                                        <td style="border: none!important;padding-left: 0;text-align: left;" class="tb_receipt" v-html="d.description"></td>
                                        <td style="border: none!important;text-align: center;" v-if="f.allowUom" class="tb_receipt">{{ d.uom.code }}</td>
                                        <td style="border: none!important;text-align: center;" v-if="f.allowQty" class="tb_receipt">{{ d.qty }}</td>
                                        <td style="border: none!important;text-align: right;" v-if="f.allowPrice" class="tb_receipt">{{ numberFormatS(d.price, printObj.baseRate.code) }}</td>
                                        <td style="border: none!important;text-align: right;" v-if="f.allowDis" class="tb_receipt">{{ numberFormatS(d.discount, printObj.baseRate.code) }}</td>
                                        <td style="border: none!important;text-align: right;" class="tb_receipt">{{ numberFormatS(d.amount, printObj.baseRate.code) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                    <div style="width: 100%;padding: 0;border-top: 1px solid #000;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.subTotal}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.subTotal,printObj.baseRate.code) }} 
                        </div>
                    </div>
                    <div style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.discount}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.discount, printObj.baseRate.code) }} 
                        </div>
                    </div>
                    <div v-if="printObj.otherCharge > 0" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.otherCharge}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.otherCharge, printObj.baseRate.code) }} 
                        </div>
                    </div>
                    <div v-if="f.allowTax" style="width: 100%;padding: 0;overflow: hidden;">
                        <div v-if="printObj.vat > 0" style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.vat}}
                        </div>
                        <div v-if="printObj.vat > 0" style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.vat, printObj.baseRate.code) }} 
                        </div>
                        <div v-if="printObj.spTax > 0" style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.spTax}}
                        </div>
                        <div v-if="printObj.spTax > 0" style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.spTax, printObj.baseRate.code) }} 
                        </div>
                        <div v-if="printObj.plTax > 0" style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.plTax}}
                        </div>
                        <div v-if="printObj.plTax > 0" style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.plTax, printObj.baseRate.code) }} 
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;border-top: 1px solid #000!important;">
                            {{dt.tax}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;border-top: 1px solid #000!important;text-align: right;font-weight: bold;">
                            {{ numberFormatS(printObj.tax, printObj.baseRate.code) }} 
                        </div>
                    </div>
                    <div style="width: 100%;padding: 0;border-top: 1px solid #000;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{ dt.totalAs }} ({{ printObj.baseRate.code }})
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 16px;">
                            {{numberFormatS(printObj.total, printObj.baseRate.symbol)}} {{printObj.baseRate.symbol}}
                        </div>
                    </div>
                    <div style="width: 100%;padding: 0;border-bottom: 1px solid #000;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{ dt.totalAs }} ({{ printObj.secondRate.code }})
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;font-size: 16px;">
                            {{ numberFormatS(parseFloat(printObj.secondRate.rate) != 1 ? (printObj.total * parseFloat(printObj.secondRate.rate)) : (printObj.total * parseFloat(printObj.baseRate.rate)), printObj.secondRate.symbol)}} {{printObj.secondRate.symbol}}
                        </div>
                    </div>
                    <div v-if="f.allowPaid" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.paidBy}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{printObj.paidBy}}
                        </div>
                    </div>
                    <div v-if="printObj.paidBy == 'Point' && printObj.pointPaid.hasOwnProperty('totalPoint')" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.pointBalance}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ (parseFloat(printObj.pointPaid.totalPoint) - parseFloat(printObj.total)).toFixed(2)}}
                        </div>
                    </div>
                    <div v-if="printObj.isTopUpCounter || printObj.isTopUpGCard" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.pointBalance}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{ (parseFloat(printObj.loyaltyCard.totalPoint) + parseFloat(printObj.total)).toFixed(2)}}
                        </div>
                    </div>
                    <div v-if="printObj.paidBy == 'Cash'">
                        <div v-if="f.allowReciept" style="width: 100%;padding: 0px;border-top: 1px solid #000;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{ dt.receiptAs }} ({{ printObj.baseRate.code }})
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                                {{numberFormat(printObj.usdPaidAmt)}} {{printObj.baseRate.symbol}}
                            </div>
                        </div>
                        <div v-if="f.allowReciept" style="width: 100%;padding: 0px;border-bottom: 1px solid #000;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{ dt.receiptAs }} ({{ printObj.secondRate.code }})
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                                {{numberFormatS(printObj.khrPaidAmt, printObj.secondRate.symbol)}} {{printObj.secondRate.symbol}}
                            </div>
                        </div>
                        <div v-if="f.allowChange" style="width: 100%;padding: 0px;border-top: 1px solid #000;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{ dt.changeAs }} ({{ printObj.baseRate.code }})
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                                {{numberFormat(printObj.exchange.base)}} {{printObj.baseRate.symbol}}
                            </div>
                        </div>
                        <div v-if="f.allowChange" style="width: 100%;padding: 0px;border-bottom: 1px solid #000;overflow: hidden;">
                            <div style="width: 50%; float: left; padding: 0;margin: 0;">
                                {{ dt.changeAs }} ({{ printObj.secondRate.code }})
                            </div>
                            <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                                {{numberFormatS(printObj.exchange.secondary, printObj.secondRate.symbol)}} {{printObj.secondRate.symbol}}
                            </div>
                        </div>
                    </div>
                    <div v-if="f.note!=''" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.note}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{f.note}}
                        </div>
                    </div>
                    <div v-if="f.wifiPwd!=''" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 50%; float: left; padding: 0;margin: 0;">
                            {{dt.wifi}}
                        </div>
                        <div style="width: 50%; float: left; padding: 0;margin: 0;text-align: right;font-weight: bold;">
                            {{f.wifiPwd}}
                        </div>
                    </div>
                    <div v-if="f.allowSignature" style="width: 100%;padding: 0;overflow: hidden;border-top: 1px solid #000;margin: 10px 0;">
                        <div style="width: 40%;overflow: hidden;float:left; text-align: left;height: 100px;border-bottom: 1px solid #000; padding: 0;margin: 0;">
                            {{dt.signature}}
                        </div>
                        <div style="width: 40%;overflow: hidden;float:right; text-align: right;height: 100px;border-bottom: 1px solid #000; padding: 0;margin: 0;">
                            {{dt.note}}
                        </div>
                    </div>
                    <div v-if="f.thankWord!=''" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 100%; text-align: center; padding: 0;margin: 0;">
                            {{f.thankWord}}
                        </div>
                    </div>
                    <div v-if="printObj.memo != ''" style="width: 100%;padding: 0;overflow: hidden;">
                        <div style="width: 100%; text-align: center; padding: 0;margin: 0;">
                            Memo: {{ printObj.memo }}
                        </div>
                    </div>
                    <!-- TXN QR -->
                    <div v-if="isTXNQR" style="overflow: hidden;text-align: center;">
                        <div style="padding: 5px; width: 140px; min-height: 140px; margin: 10px auto;">
                            <i style="font-size: 10px; color: #000!important">Scan with loyalty app.</i>
                            <div style="background-color: #000;margin-top: 5px;min-height: 140px;width: 100%;border-radius: 10px;">
                                <div class="covertxnqr" style="background-color: rgb(255, 255, 255);position: relative;height: 132px;margin: 5px;overflow: hidden; border-radius: 5px !important;">
                                    <qr-code style="padding: 10px;margin-top: 5px" :text="printObj.txnQr"></qr-code>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--ABA-->
                    <div v-if="isABAKHQR" style="overflow: hidden;text-align: center;">
                        <div style="padding: 5px; width: 140px; min-height: 140px; margin: 10px auto;">
                            <i style="font-size: 10px; color: #000!important">Scan, Pay, Done.</i>
                            <div style="background-color: #000;margin-top: 5px;min-height: 140px;width: 100%;border-radius: 10px;">
                                <img src="@/assets/logo/khqr_black.png" style="height: 20px; width: auto;margin: 10px 0" />
                                <div style="background-color: #fff;position: relative; height: 140px;margin: 5px;overflow: hidden;border-radius: 0 0 5px 5px!important;">
                                    <div style="background-color: rgb(0, 0, 0); position: absolute; top: -100px; right: -57px; transform: rotate(-45deg); height: 100px; width: 100px;"></div>
                                    <img
                                        class="img-1"
                                        style="margin-top: 10px;"
                                        :src="khqrUrl"
                                        width="100%"
                                    />
                                </div>
                                
                            </div>
                            <img src="@/assets/logo/abafooter.png" style="height: 14px; width: auto;margin: 10px 0" />
                        </div>
                    </div>
                    <v-col class="text-right my-4" sm="12" cols="12" style="text-align: center!important;margin: 0!important;padding: 6px;background: none;border-top: 1px solid #eee;overflow: hidden;">
                        <img src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/benchlogo.png" style="height: 40px; width: auto;" />
                    </v-col>
                </div>
            </div>
            <div v-else-if="printObj.type == 'return'">
                <div style="padding-top: 10px;overflow: hidden;">
                    <div style="width: 100%;border-bottom: 1px solid #000;overflow: hidden;position: relative;">
                        <div style="width: 25%; padding: 0; text-align: left;position: absolute; left: 0; top: 0;">
                            <img
                                class="mt-0 logo"
                                style="height:90px;width: auto;margin: 0;"
                                :src="logoUrl"
                                alt=""
                            />
                        </div>
                        <div style="width: 100%; float: left;text-align: center;padding: 0;">
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 10px;font-weight: bold;padding: 0px;padding-bottom: 0px;font-family: 'Khmer OS Muol';">
                                ឌីខេអេចអិន ក្លាសស៊ី ស៊ីកនេតឈ័រ ឯ.ក
                            </div>
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: bold;padding: 0px;padding-bottom: 0;">
                                DKHN KLASSY SIGNATURE CO., LTD.
                            </div>
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                លេខអតត្តសញ្ញាណកម្មសារពើពន្ធ (VAT-TIN): {{ institute.vatTin }}
                            </div>
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                អាស័យដ្ឋាន៖ <span style="font-size: 9px;" v-html="institute.companyAddress"></span>
                            </div>
                            <div style="width: 100%; padding: 0; text-align: center;font-size: 9px;font-weight: normal;padding: 0px;">
                                លេខទូរស័ព្ទ/ Tel: {{ institute.companyPhone }}
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;text-align: center;overflow: hidden;">
                        <div style="width: 100%; padding: 0; text-align: center;font-size: 12px;font-weight: bold;line-height: 14px;padding-top: 10px; font-family: 'Khmer OS Muol';">
                            <span style="font-size: 12px;font-weight: bold;line-height: 14px;padding-top: 10px; font-family: 'Khmer OS Muol';" >លិខិតឥណទានអតប</span>
                            <br>
                            <span style="font-size: 8px;" >Sale Return</span>
                        </div>
                    </div>
                    <div style="width: 100%;text-align: center;overflow: hidden;">
                        <div style="width: 60%;overflow: hidden;text-align: left;float: left;font-size: 9px;font-weight: bold;">
                            <div style="width: 100%;overflow: hidden;">អតិថិជន/ CUSTOMER:</div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                    ឈ្មោះអតិថិជន៖
                                </div>
                                <div style="width: 75%;float: left;overflow: hidden;font-size: 9px;">
                                    {{printObj.customer.name}}
                                </div>
                            </div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                    អាស័យដ្ឋាន៖
                                </div>
                                <div style="width: 75%;float: left;overflow: hidden;font-weight: normal;font-size: 9px;">
                                    {{printObj.customer.address}}
                                </div>
                            </div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 25%;float: left;overflow: hidden;font-size: 9px;">
                                    លេខទូរស័ព្ទ៖
                                </div>
                                <div style="width: 75%;float: left;overflow: hidden;font-size: 9px;">
                                    {{printObj.customer.phone}}
                                </div>
                            </div>
                        </div>
                        <div style="width: 40%;overflow: hidden;text-align: right;float: left;font-weight: bold;font-size: 9px;">
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                    លេខវិក័យប័ត្រ៖
                                </div>
                                <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                    {{printObj.txnNumber}}
                                </div>
                            </div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                    កាលបរិច្ឆេទ៖
                                </div>
                                <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                    {{dateTimeFormat(printObj.issuedDate)}}
                                </div>
                            </div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                    អ្នកគិតលុយ៖
                                </div>
                                <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                    {{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}
                                </div>
                            </div>
                            <div style="width: 100%;overflow: hidden;clear:both">
                                <div style="width: 35%;float: left;overflow: hidden;font-size: 9px;">
                                    អាត្រាប្តូរប្រាក់៖
                                </div>
                                <div style="width: 65%;float: left;overflow: hidden;font-size: 9px;">
                                    1 USD = {{printObj.exchangeRate.replace('KHR', '')}} រៀល
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;padding: 0;overflow: hidden;padding-top: 10px;">
                        <v-simple-table fixed-header  class="flex-1" style="border: 1px solid #000!important;" >
                            <template v-slot:default >
                                <thead class="th_invoice" >
                                    <tr>
                                        <th style="border: 0px;background: none;padding-left: 10px; text-align: left!important;border:none!important;font-size: 9px !important;" width="500" class=" th_invoice text-left">
                                            បរិយាយមុខទំនិញ <br>Description
                                        </th>
                                        <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;"  width="100" class=" th_invoice text-left">
                                            បរិមាណ<br>Quantity
                                        </th>
                                        <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;" width="150" class=" th_invoice text-left">
                                            តម្លៃឯកតា<br>Price
                                        </th>
                                        <th style="border: 0px;text-align: right!important;border:none!important;font-size: 9px !important;" width="150" class=" th_invoice_r text-left">
                                            សរុប<br>Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr 
                                        v-for="d in printObj.itemLines" 
                                        v-bind:key="d.id" >
                                        <td style="border: none!important;text-align: left;font-size: 9px !important;padding-left: 10px!important;" class="tb_receipt" v-html="d.description"></td>
                                        <td style="border: none!important;text-align: right;padding: 0;font-size: 9px !important;" class="tb_receipt">{{ d.qty }}</td>
                                        <td style="border: none!important;text-align: right;padding: 0;font-size: 9px !important;" class="tb_receipt">{{ numberFormatS(d.price, printObj.baseRate.code) }}</td>
                                        <td style="border: none!important;text-align: right;font-size: 9px !important;padding-right: 10px;" class="tb_receipt">{{ numberFormatS(d.amount, printObj.baseRate.code) }}</td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 1">
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 1">
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 1">
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 2" >
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 2">
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                    <tr v-if="printObj.itemLines.length == 3">
                                        <td colspan="4" style="height: 26.5px;"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr style="padding: 2px; border-top: 1px solid #000;">
                                        <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                            <span style="padding: 2px;font-size: 9px;" >សរុប(ដុល្លារ) / Subtotal (USD)</span>
                                        </td>
                                        <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                            {{ numberFormatS(printObj.subTotal,printObj.baseRate.code) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                            <span style="padding: 2px;font-size: 9px;" >បញ្ចុះតំលៃ(ដុល្លារ) / Discount (USD)</span>
                                        </td>
                                        <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                            {{ numberFormatS(printObj.discount, printObj.baseRate.code) }}
                                        </td>
                                    </tr>
                                    <tr v-if="printObj.invoiceType == 'vat'">
                                        <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                            <span style="padding: 2px;font-size: 9px;" >អាករលើតម្លៃបន្ថែម (VAT 10%)</span>
                                        </td>
                                        <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                            {{ numberFormatS(printObj.tax, printObj.baseRate.code) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;padding-top: 5px;">
                                            <span style="padding: 2px;font-size: 9px;" >សរុបរួម(ដុល្លារ) / Grand Total (USD)</span><br>
                                            <span style="padding: 2px;font-size: 9px;" v-if="printObj.invoiceType == 'commercial'">(រួមបញ្ចូលទាំងពន្ធ / All VAT Included)</span>
                                        </td>
                                        <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important;padding-right: 10px;">
                                            {{numberFormatS(printObj.total, printObj.baseRate.symbol)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="font-size: 9px;text-align: right; padding-right: 10px;font-weight: normal;padding-top: 5px;">
                                            <span style="padding: 2px;font-size: 9px;" >ទឹកប្រាក់សរុបជារៀលសម្រាប់កាតព្វកិច្ចប្រកាសពន្ធតែប៉ុណ្ណោះ <br>(Total Amount in KHR for Tax Filling Obligation Only):</span>
                                        </td>
                                        <td style="text-align: right;font-size: 9px;font-weight: bold;background-color: #ccc!important; padding-right: 10px;">
                                            {{ numberFormatS(parseFloat(printObj.secondRate.rate) != 1 ? (printObj.total * parseFloat(printObj.secondRate.rate)) : (printObj.total * parseFloat(printObj.baseRate.rate)), printObj.secondRate.symbol)}} រៀល
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                    <div style="width: 100%;overflow: hidden;text-align: left;float: left;font-weight: bold;font-size: 9px;margin-top: 10px;">
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: bold;">
                            លក្ខខណ្ឌផ្សេងៗ៖
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            1. សូមពិនិត្យទំនិញឱ្យបានត្រឹមត្រូវមុននឹងចាកចេញ ឬក៏ទទួលយក។
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            2. អតិថិជន ឯកភាពថានាឡិកាដែលបានយកចេញទៅគឺ មានសភាពថ្មី ១០០% ។
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            3. យើងខ្ញុំពុំទទួលខុសត្រូវរាល់ការ បែកបាក់ ខូតខាត ឫឆ្កូត បន្ទាប់ពីអតិថិជនបានចុះហត្ថលេខាទទួលឡើយ។
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            4. ទំនិញឫនាឡិកាដែលជាកាដូពីហាង ឬជាការបញ្ចុះតម្លៃពុំអាចធ្វើការផ្លាស់ប្តូរបានទេ។
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            5. អតិថិជនអាចធ្វើការផ្លាស់ប្តូរទំនិញឫនាឡិកាបានក្នុងរយៈពេល ៧២ម៉ោង រាប់ពីពេលដែលអតិថិជនធ្វើការជាវ ក្នុងលក្ខខណ្ឌដូចខាងក្រោម៖
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.1 ទំនិញឬនាឡិកាដែលទិញនៅថ្មីមិនទាន់បានប្រើប្រាស់ ទក់ស្បែក កូដ កាត់ ចោះខ្សែ ឬដាច់ប្លាស្ទិកគ្របតួរបស់នាឡិកាដែលខុសទ្រង់ទ្រាយថ្មី។</p>
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.2 ក្នុងករណីអតិថិជនប្តូរជាមួយទំនិញដែលមានតម្លៃទាបជាងតម្លៃទំនិញដែលត្រូវប្តូរ ខាងយើងខ្ញុំនឹងមិនប្រគល់ទឹកប្រាក់ដែលនៅសល់នោះទេ។</p>
                        </div>
                        <div style="width: 100%;float: left;overflow: hidden;font-size: 9px;font-weight: normal;">
                            <p style="font-size: 9px;padding-left: 20px;margin: 0;">5.3 មិនអនុញ្ញាតិអោយមានការផ្លាស់ប្តូរទំនិញឫនាឡិការលើសពី១លើកទេ។</p>
                        </div>
                    </div>
                    <div style="overflow: hidden;width: 100%;padding-top: 15px;">
                        <div style="width: 100%;overflow: hidden;position: relative;text-align: left;clear: both">
                            <div style="width: 30%;text-align: center; float: left;font-size: 9px;">
                                បានអាន និងយល់ស្របតាមលក្ខខណ្ឌខាងលើ
                            </div>
                        </div>
                        <div style="width: 30%; float: left;height: 90px;border-bottom: 1px solid #000;text-align: center;font-size: 9px;font-weight: normal;">
                        
                            <span style="font-size: 9px;font-weight: bold;">
                                ហត្ថលេខាននិងឈ្មោះអតិថិជន
                            </span>
                        </div>
                        <div style="width: 30%; float: right;height: 90px;border-bottom: 1px solid #000;text-align: center;font-size: 9px;font-weight: normal;">
                            
                            <span style="font-size: 9px;font-weight: bold;">
                                ហត្ថលេខានិងឈ្មោះអ្នកលក់
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-row>
</template>

<script>
// import { i18n } from "@/i18n";
import generalSettingModel from "@/scripts/commerce/model/GeneralSetting"
import kendo from "@progress/kendo-ui"
import { uuid } from "vue-uuid"
const intHandler = require("@/scripts/instituteHandler")
import VueQRCodeComponent from 'vue-qrcode-component'
const inst = localStorage.getItem('institute') != null ? JSON.parse(localStorage.getItem('institute')) : {}
const instituteId = inst.id || ''
export default {
    name: "PrintReceipt",
    props: {printObj: {}},
    data: () => ({
        miniVariant: false,
        isShow: false,
        kendo: kendo,
        decimal: 0,
        logoUrl: '',
        institute: localStorage.getItem('institute') != null ? JSON.parse(localStorage.getItem('institute')) : {},
        counterName: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')).name : '',
        activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
        register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
        receiptTemplate: 'A4',
        isHideColumn: false,
        f: new generalSettingModel({}).template,
        setting: localStorage.getItem(instituteId + 'commRSetting') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRSetting')) : {},
        dt: {},
        g: {},
        isABAKHQR: false,
        isTXNQR: false,
        khqrUrl: ''
    }),  
    methods: {
        timeFormat(date){
            return kendo.toString(new Date(date), 'HH:mm tt');
        },
        dateTimeFormat(date){
            return kendo.toString(new Date(date), 'dd/MMM/yyyy HH:mm tt');
        },
        numberFormat(value) {
            if(value == undefined){
                value = 0
            }
            return kendo.toString(parseFloat(value), `n${this.decimal}`);
        },
        numberFormatS(value, currency) {
            if(currency == 'KHR'){
                let roundpu = this.setting.allowRoundUp || false
                if(roundpu == 1){
                    roundpu = true
                }
                if(roundpu){
                    value = Math.ceil(parseFloat(value)/100)*100
                }
                
                if(value == undefined){
                    value = 0
                }
                return kendo.toString(parseFloat(value), `n0`);
            }
            
            if(value == undefined){
                value = 0
            }
            return kendo.toString(parseFloat(value), `n${this.decimal}`);
        },
        langChange(){
            if(this.f.lang == 2){
                // kh
                this.dt.vat = 'លេខអត្តសញ្ញាកម្មសារពើពន្ធ'
                this.dt.address = 'អាស័យដ្ឋាន'
                this.dt.phone = 'លេខទូរស័ព្ទ'
                this.dt.title = 'វិក្កយបត្រ'
                this.dt.number = 'លេខ'
                this.dt.storeName = 'ឈ្មោះហាង'
                this.dt.cashier = 'អ្នកគិតប្រាក់'
                this.dt.customer = 'អតិថិជន'
                this.dt.date = 'កាលបរិច្ឆេទ'
                this.dt.counter = 'ម៉ាស៊ីនគិតប្រាក់'
                this.dt.exchange = 'អត្រាប្តូរប្រាក់'
                this.dt.description = 'ការពិពណ៌នា'
                this.dt.unit = 'ឯកតា'
                this.dt.qty = 'បរិមាណ'
                this.dt.price = 'តម្លៃ'
                this.dt.discount = 'ចុះថ្លៃ'
                this.dt.amount = 'ចំនួនទឹកប្រាក់'
                this.dt.subTotal = 'សរុបរង'
                this.dt.spTax = 'អាករពិសេស'
                this.dt.plTax = 'អាករបំភ្លឺសាធារណះ'
                this.dt.vat = 'អាករលើតម្លៃបន្ថែម ១០%'
                this.dt.tax = 'ពន្ធសរុប'
                this.dt.totalAs = 'សរុបជា'
                this.dt.paidBy = 'ទូទាត់តាម'
                this.dt.note = 'កំណត់ត្រា'
                this.dt.wifi = 'ពាក្យសំងាត់ Wifi'
                this.dt.saleunit = 'ឯកតាលក់'
                this.dt.customerType = 'ប្រភេទអតិថិជន'
                this.dt.timeIn = 'ម៉ោងចូល'
                this.dt.timeOut = 'ម៉ោងចេញ'
                this.dt.orderNum = 'លេខរងចាំ'
                this.dt.changeAs = 'ប្រាក់អាប់ជា'
                this.dt.receiptAs = 'ប្រាក់ទទួលជា'
                this.dt.otherCharge = 'ចំណាយបន្ថែម'
                this.dt.signature = 'ហត្ថលេខា'
                this.dt.pointBalance = 'ពិន្ទុនៅសល់'
                if(this.g.allowTax){
                    if(this.g.taxAmt == 2){
                        this.dt.totalAs = 'សរុបរួមបញ្ចូលទាំងអាករ'
                    }
                }
            }else if(this.f.lang == 3){
                // end
                this.dt.vat = 'Vattin'
                this.dt.address = 'Address'
                this.dt.phone = 'Phone'
                this.dt.title = 'Invoice'
                this.dt.number = 'No.'
                this.dt.storeName = 'Store'
                this.dt.cashier = 'Cashier'
                this.dt.customer = 'Customer'
                this.dt.date = 'Date'
                this.dt.counter = 'Counter'
                this.dt.exchange = 'Exchange Rate'
                this.dt.description = 'Des.'
                this.dt.unit = 'Uom'
                this.dt.qty = 'Qty'
                this.dt.price = 'Price'
                this.dt.discount = 'Dis.'
                this.dt.amount = 'Amount'
                this.dt.subTotal = 'Sub total'
                this.dt.spTax = 'SP Tax'
                this.dt.plTax = 'PL Tax'
                this.dt.vat = 'VAT (10%)'
                this.dt.tax = 'Total Tax'
                this.dt.totalAs = 'Total'
                this.dt.paidBy = 'Paid by'
                this.dt.note = 'Note'
                this.dt.wifi = 'Wifi Password'
                this.dt.saleunit = 'Sale Unit'
                this.dt.customerType = 'Customer Type'
                this.dt.timeIn = 'Time In'
                this.dt.timeOut = 'Time Out'
                this.dt.orderNum = 'Order No.'
                this.dt.changeAs = 'Change Amount as'
                this.dt.receiptAs = 'Reciept Amount as'
                this.dt.otherCharge = 'Other Charge'
                this.dt.signature = 'Signature'
                this.dt.pointBalance = 'Point Balance'
                if(this.g.allowTax){
                    if(this.g.taxAmt == 2){
                        this.dt.totalAs = 'Total (All Tax Included)'
                    }
                }
            }else{
                // both
                this.dt.vat = 'លេខ Vattin'
                this.dt.address = 'អស័យដ្ឋាន (Address)'
                this.dt.phone = 'លេខទូស័ព្ទ (Phone)'
                this.dt.title = 'វិក្កយបត្រ (Invoice)'
                this.dt.number = 'លេខ (No.)'
                this.dt.storeName = 'ហាង (Store)'
                this.dt.cashier = 'អ្នកគិតប្រាក់ (Cashier)'
                this.dt.customer = 'អតិថិជន (Customer)'
                this.dt.date = 'កាលបរិច្ឆេទ (Date)'
                this.dt.counter = 'កន្លែងគិតប្រាក់ (Counter)'
                this.dt.exchange = 'អត្រាប្តូរប្រាក់ (Ex. Rate)'
                this.dt.description = 'ព័ណនា (Des.)'
                this.dt.unit = 'ខ្នាត (Uom)'
                this.dt.qty = 'ចំនួន (Qty)'
                this.dt.price = 'ថ្លៃ (Price)'
                this.dt.discount = 'ចុះថ្លៃ (Dis.)'
                this.dt.amount = 'ទឹកប្រាក់ (Amount)'
                this.dt.subTotal = 'សរុបរង (Sub total)'
                this.dt.spTax = 'អាករពិសេស (SP Tax)'
                this.dt.plTax = 'អាករបំភ្លឺសាធារណះ (PL Tax)'
                this.dt.vat = 'អាករលើតម្លៃបន្ថែម (VAT 10%)'
                this.dt.tax = 'ពន្ធសរុប (TAX)'
                this.dt.totalAs = 'សរុប (Total)'
                this.dt.paidBy = 'បង់ប្រាក់តាម (Paid by)'
                this.dt.note = 'កត់ចំណាំ (Note)'
                this.dt.wifi = 'លេខកូដ (Wifi Password)'
                this.dt.saleunit = 'ឯកតាលក់ (Sale Unit)'
                this.dt.customerType = 'ប្រភេទអតិថិជន (Cus. Type)'
                this.dt.timeIn = 'ម៉ោងចូល (Time In)'
                this.dt.timeOut = 'ម៉ោងចេញ (Time Out)'
                this.dt.orderNum = 'លេខរងចាំ (Order No.)'
                this.dt.changeAs = 'ប្រាក់អាប់ជា (Change Amt. As)'
                this.dt.receiptAs = 'ប្រាក់ទទួលជា (Reciept Amt. As)'
                this.dt.otherCharge = 'ចំណាយបន្ថែម​(Other Chrg.)'
                this.dt.signature = 'ហត្ថលេខា (Signature)'
                this.dt.pointBalance = 'ពិន្ទុនៅសល់ (Point Bal.)'
                if(this.g.allowTax){
                    if(this.g.taxAmt == 2){
                        this.dt.totalAs =  'សរុបបញ្ចូលទាំងអាករជា​ Total (All Tax Included) As'
                    }
                }
            }
            this.isShow = false
            setTimeout(()=>{
                this.isShow = true
            }, 30)
        },
        async loadSetting(){
            this.isHideColumn = false
            const data = []
            data.push(this.setting)
            if (data.length > 0) {
                this.receiptTemplate = data[0].receiptTemplate || 'A4'
                this.decimal = data[0].decimal
                let pnt = this.printObj
                let isABAKHQR = pnt.isABAKHQR || false
                this.isABAKHQR = isABAKHQR
                if(isABAKHQR){
                    this.khqrUrl = pnt.khqrUrl
                }
                let txnqr = pnt.txnQr || ''
                this.isTXNQR = false
                if(txnqr != ''){
                    this.isTXNQR = true
                }
                let g = new generalSettingModel(data[0])
                this.g = g
                this.f = g.template
                let invtype = pnt.invoiceType || 'normal'
                if(invtype == 'commercial'){
                    this.f = g.comtemplate
                }else if(invtype == 'vat'){
                    this.f = g.vattemplate
                }
                pnt.customer = pnt.customer || {}
                let cutype = pnt.customer.type || 'general'
                let t = typeof cutype
                if(t == 'object'){
                    let c = cutype || {}
                    cutype = c.S
                }
                pnt.customer.type = cutype
                let cuname = pnt.customer.name
                t = typeof cuname
                if(t == 'object'){
                    let c = cuname || {}
                    cuname = c.S
                }
                pnt.customer.name = cuname
                let cuphone = pnt.customer.phone 
                t = typeof cuphone
                if(t == 'object'){
                    let c = cuphone || {}
                    cuphone = c.S
                }
                pnt.customer.phone = cuphone
                let cuaddress = pnt.customer.address 
                t = typeof cuaddress
                if(t == 'object'){
                    let c = cuaddress || {}
                    cuaddress = c.S
                }
                pnt.customer.address = cuaddress
                pnt.pinUser =  pnt.pinUser || {}
                let pinname = pnt.pinUser.name 
                t = typeof pinname
                if(t == 'object'){
                    let c = pinname || {}
                    pinname = c.S
                }
                pnt.pinUser.name = pinname
                this.f.title = this.f.title || ''
                this.f.timeIn = this.f.timeIn || false
                this.f.customerType = this.f.customerType || false
                this.f.timeOut = this.f.timeOut || false
                this.f.orderNum = this.f.orderNum || false
                this.f.wifiPwd = this.f.wifiPwd || ''
                this.f.thankWord = this.f.thankWord || ''
                this.f.note = this.f.note || ''
                this.f.allowChange = this.f.allowChange || false
                let allowReciept = this.f.allowReciept || false
                if(allowReciept == 1){
                    allowReciept = true
                }
                this.f.allowReciept = allowReciept
                let printData = []
                let printAmt = 1
                if(data[0].template.hasOwnProperty('printAmt')){
                    printAmt = parseInt(data[0].template.printAmt)
                }
                if(printAmt <= 0){
                    printAmt = 1
                }
                for(let i = 0; i < printAmt; i++){
                    let printo = {
                        ...pnt,
                        printId: uuid.v1()
                    }
                    printData.push(printo)
                }
                this.printData = printData
                this.langChange()
            }
        }
    },
    components: {
        'qr-code': VueQRCodeComponent
    },
    computed:{
        isKlassy(){
            if(instituteId == 'inst-72006786'){
                return true
            }
            return false
        },
        totalItems(){
            if(this.printObj.itemLines.length > 0){
                let total = 0
                this.printObj.itemLines.forEach(itm=>{
                    total += itm.qty
                })
                return total
            }
            return 0;
        },
    },
    watch: {
        'printObj': 'loadSetting'
    },
    created: async function () {
        await this.loadSetting()
        let r = this.register
        if(r.phone != ''){
            this.institute = {
                vatTin: r.vat,
                companyAddress: r.address,
                companyPhone: r.phone
            }
        }
        let hasLogo = false
        if(r.hasOwnProperty('logo')){
            if(Object.keys(r.logo).length > 0){
                if(r.logo.hasOwnProperty('default')){
                    if(r.logo.default.thumb != ''){
                        hasLogo = true
                    }
                }
            }
        }
        if(hasLogo){
            this.logoUrl = 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + r.logo.default.thumb
        }else{
            intHandler.getImage(this.institute.userId).then(res=>{
                if(res.length > 0){
                    this.logoUrl = res[0].url || ''
                }
            })
        }
        if(Object.keys(this.register.logo).length > 0){
            this.logoUrl = 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + this.register.logo.default.thumb
        }
    },
};
</script>
<style scoped>
    
    .tb_receipt{
        border: 0.2px solid black;
        border-right: 0px;
    }
    .tb_receipt_r{
        border: 0.2px solid black;
    }
    .th_invoice{
        border: 0.2px solid black;
        border-right: 0px;
        color: black !important;
        font-weight: 700;
    }
    .th_invoice_r{
        border: 0.2px solid black;
        color: black !important;
        font-weight: 700;
    }
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    }   
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: left;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
        min-width: 20% !important;
        margin: 4px;
        padding: 0 2px !important;
        font-size: 13px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 12px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        border-bottom: none !important;
        height: 20px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-order_type:before {
        content: "\e933";
        color: #ffffff;
        font-size: 22px;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 22px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 22px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 22px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 22px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 22px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 22px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 22px;
    }

</style>